<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo style="height: 40px" />
        <h2 class="brand-text text-primary ml-1">
          Prime Survey
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="
                        w-100
                        d-lg-flex
                        align-items-center
                        justify-content-center
                        px-5
                    "
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Welcome to Prime Survey! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{ invalid }"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="
                      errors.length > 0 ? false : null
                    "
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <!--                  <b-link-->
                  <!--                    :to="{ name: 'auth-forgot-password' }"-->
                  <!--                  >-->
                  <!--                    <small>Forgot Password?</small>-->
                  <!--                  </b-link>-->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="
                      errors.length > 0
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="
                        errors.length > 0 ? false : null
                      "
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="
                          togglePasswordVisibility
                        "
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'page-register' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import { email, required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    const isAcrossLogin = typeof this.$route.query.alk === 'string'
    if (isAcrossLogin) {
      this.acrossLogin()
    } else if (this.isLoggedIn()) {
      this.$router.push({ path: '/' })
    } else {
      this.hideLoading()
    }
  },
  methods: {
    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn']
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.showLoading()
          this.$http
            .post('/api/auth/login', {
              email: this.userEmail,
              password: this.password,
            })
            .then(response => {
              const returnData = response.data
              const isLoggedInSuccess = typeof returnData.accessToken === 'string'
                    && typeof returnData.publisherInfo === 'object'
                    && returnData.publisherInfo !== null
              if (isLoggedInSuccess) {
                this.$store.dispatch('auth/login', {
                  accessToken: returnData.accessToken,
                  publisherInfo: returnData.publisherInfo,
                })
              } else {
                this.hideLoading()
                this.alertError('Login invalid')
              }
            })
            .catch(() => {
              this.alertError('Login invalid')
              this.hideLoading()
            })
        }
      })
    },
    acrossLogin() {
      this.showLoading()
      this.$http
        .post('/api/auth/across-login', {
          key: this.$route.query.alk,
        })
        .then(response => {
          const returnData = response.data
          if (returnData.result) {
            const isLoggedInSuccess = typeof returnData.accessToken === 'string'
                  && typeof returnData.publisherInfo === 'object'
                  && returnData.publisherInfo !== null
            if (isLoggedInSuccess) {
              this.$store.dispatch('auth/login', {
                accessToken: returnData.accessToken,
                publisherInfo: returnData.publisherInfo,
              })
            } else {
              this.hideLoading()
              this.$store.dispatch('auth/logout')
            }
          } else {
            this.hideLoading()
          }
        })
        .catch(() => {
          this.hideLoading()
          this.$store.dispatch('auth/logout')
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
